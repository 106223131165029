import "./styles.scss";

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { useLocale } from "@context/locale";

import Button from "@components/Button";

function ReservationTiles({ items }) {
  const { t } = useLocale();

  return (
    <div className="reservation-tiles">
      {items?.map((reservation, index) => {
        const image = getImage(reservation?.image?.gatsbyImage);
        return (
          <div key={index} className="reservation-tile">
            <div className="reservation-tile__image">
              {!!image && <GatsbyImage image={image} alt="" />}
            </div>

            <h5 className="reservation-tile__title">{reservation.title}</h5>
            <p className="reservation-tile__description">
              {reservation.description}
            </p>
            <Button
              href={reservation.link}
              className="reservation-tile__button"
            >
              {t("Rezerwuj")}
            </Button>
          </div>
        );
      })}
    </div>
  );
}

export default ReservationTiles;
