import * as React from "react";
import { graphql } from "gatsby";

import Breadcrumbs from "@components/Breadcrumbs";
import Seo from "@components/Seo";

import ReservationTiles from "@components/ReservationTiles";
import ActualPromotions from "@components/ActualPromotions";

import OfferCard from "@page_components/offer/OfferCard";

function OfferPageEN({ data }) {
  const { seo } = data?.seo_data?.nodes[0];
  const breadcrumbs_data = [{ name: "Offer and price list", href: `/offer` }];
  const offers = data?.allWpPage.nodes;
  const reservation_items =
    data?.reservation_data.nodes[0]?.pageHome?.reservation;

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="offers-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />
          <main>
            <h1> Offer and price list </h1>
            <div className="offers-page__price-list">
              {offers.map((offer) => {
                if (offer?.pageOffer?.suboffers) {
                  if (offer?.pageOffer?.suboffers[0].suboffersItem) {
                    return (
                      <OfferCard
                        title={offer.title}
                        items={offer.pageOffer.suboffers}
                        slug={offer.slug}
                        key={offer.slug}
                      />
                    );
                  }

                  return null;
                }
                return null;
              })}
            </div>
          </main>
        </div>

        <section className="offers-page__reservation reservation__section">
          <div className="container">
            <h2 className="reservation-section__title">Rezerwacja wizyty</h2>
            <h4 className="reservation-section__subtitle">
              Wiele wygodnych możliwości
            </h4>
            <ReservationTiles items={reservation_items} />
          </div>
        </section>

        <section className="offers-page__promotions">
          <div className="container">
            <ActualPromotions />
          </div>
        </section>
      </div>
    </>
  );
}

export default OfferPageEN;

export const query = graphql`
  {
    allWpPage(
      filter: {
        template: { templateName: { eq: "Oferta" } }
        language: { slug: { eq: "en" } }
      }
    ) {
      nodes {
        title
        slug
        pageOffer {
          suboffers {
            suboffersItem {
              ... on WpOferta {
                id
                acfSuboffer {
                  subofferPriceList {
                    procedureRemarks
                    procedurePrice
                  }
                }
                title
                slug
              }
            }
          }
        }
      }
    }
    reservation_data: allWpPage(filter: { title: { eq: "Home page" } }) {
      nodes {
        pageHome {
          reservation {
            title
            description
            link
            image {
              gatsbyImage(width: 546)
            }
          }
        }
      }
    }

    seo_data: allWpPage(filter: { title: { eq: "Offer and price list" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
      }
    }
  }
`;
