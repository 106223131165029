import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import { useLocale } from "@context/locale";

import SubOffer from "./SubOffer";

function OfferCard({ title, items, slug }) {
  const { lang } = useLocale();

  return (
    <div className="offer-card">
      <h3 className="offer-card__title">
        <Link to={lang === "en" ? `/en/${slug}` : `/${slug}`}>{title}</Link>
      </h3>
      <div className="offer-card__labels">
        <p>Rodzaj zabiegu</p>
        <p>Cena</p>
      </div>
      <ul className="offer-card__suboffers">
        {items?.map((item, index) => {
          const price = item?.suboffersItem?.acfSuboffer?.subofferPriceList
            ? item.suboffersItem.acfSuboffer.subofferPriceList[0].procedurePrice
            : null;

          return (
            <SubOffer
              name={item?.suboffersItem?.title}
              price={price}
              link={`/oferta/${item?.suboffersItem?.slug}`}
              key={index}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default OfferCard;
