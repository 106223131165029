import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import ArrowIcon from "@icons/arrow.svg";

function SubOffer({ name, price, link }) {
  if (name) {
    return (
      <li className="suboffer">
        <Link to={link} className="suboffer__hyperlink">
          <p className="suboffer__name">{name}</p>
          <div className="suboffer__right">
            {price && <span className="suboffer__price">{price} zł</span>}

            <ArrowIcon width={13} height={10} />
          </div>
        </Link>
      </li>
    );
  }

  return null;
}

export default SubOffer;
