import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

function Button({
  className = "",
  children,
  onClick,
  href, // if href not empty button will be link
  variant = "default", // default or white
}) {
  function setVariant(name) {
    if (name === "white") {
      return "button--white";
    }
    return null;
  }

  if (!href) {
    return (
      <button
        className={`button ${setVariant(variant)}  ${className}`}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    );
  }

  return (
    <Link to={href} className={`button ${setVariant(variant)}  ${className}`}>
      {children}
    </Link>
  );
}

export default Button;
